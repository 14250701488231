import domReady from "@roots/sage/client/dom-ready";
import Splide from "@splidejs/splide";

/**
 * Application entrypoint
 */
domReady(async () => {
  // ...
  mobileMenu();
  activitiesSplider();
  showAndHidePersonDescription();
  validateForm();
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);

function formEdit() {
  // Find the parent component with the class .mailerlite-form-inputs
  const parentComponent = document.querySelector(".ml-form-formContent");

  // Check if the parent component exists
  if (parentComponent) {
    // Get the second and third children
    const secondChild = parentComponent.children[1];
    const thirdChild = parentComponent.children[2];
    const fourthChild = parentComponent.children[3];

    const fourthInput = fourthChild.querySelector("input");
    fourthInput.setAttribute("type", "checkbox");

    // Create a new div element with the class mailerlite-custom
    const customDiv = document.createElement("div");
    customDiv.className = "mailerlite-custom";

    // Append the second and third children to the new div
    customDiv.appendChild(thirdChild.cloneNode(true));
    customDiv.appendChild(secondChild.cloneNode(true));

    // Replace the second and third children with the new div
    parentComponent.replaceChild(customDiv, secondChild);
    thirdChild.remove();
  }
}

function activitiesSplider() {
  new Splide("#activities-slider", {
    type: "loop",
    perPage: 3,
    gap: "3%",
    breakpoints: {
      640: {
        perPage: 1,
      },
      1024: {
        perPage: 2,
      },
    },
  }).mount();

  //   new Splide("#people-slider-1", {
  //     type: "loop",
  //     perPage: 4,
  //     gap: "32px",
  //     arrows: false,
  //     breakpoints: {
  //       640: {
  //         perPage: 1,
  //       },
  //       1024: {
  //         perPage: 2,
  //       },
  //     },
  //   }).mount();

  //   new Splide("#people-slider-0", {
  //     type: "loop",
  //     perPage: 4,
  //     gap: "32px",
  //     arrows: false,
  //     breakpoints: {
  //       640: {
  //         perPage: 1,
  //       },
  //       1024: {
  //         perPage: 2,
  //       },
  //     },
  //   }).mount();
}

function showAndHidePersonDescription() {
  const buttons = document.querySelectorAll(".show-more-button");

  buttons.forEach((button) => {
    button.addEventListener("click", () => {
      const parent = button.parentElement;
      const description = parent.querySelector(".person-description");

      if (description.classList.contains("hidden")) {
        description.classList.remove("hidden");
        button.innerHTML = "Zobrazit méně";
      } else {
        description.classList.add("hidden");
        button.innerHTML = "Zobrazit více";
      }
    });
  });
}

function mobileMenu() {
  const nav = document.querySelector(".mobile-nav");
  const expand = nav.querySelector(".menu-collapse");
  const ham = document.querySelector("#mobile-ham-button");
  const close = document.querySelector("#mobile-close-button");

  ham.addEventListener("click", () => {
    openMenu(expand, ham, close);
  });

  close.addEventListener("click", () => {
    closeMenu(expand, ham, close);
  });
}

function openMenu(expand, ham, close) {
  ham.classList.add("hidden");
  expand.classList.remove("hidden");
  expand.classList.add("flex");
  close.classList.remove("hidden");
}

function closeMenu(expand, ham, close) {
  ham.classList.remove("hidden");
  expand.classList.add("hidden");
  close.classList.add("hidden");
}

function validateForm() {
  const form = document.querySelector("#signup-form");
  const checkbox = form.querySelector("#checkbox-field");
  const email = form.querySelector("#email-field");
  const emailError = form.querySelector("#email-validation");
  const checkboxError = form.querySelector("#checkbox-validation");

  form.addEventListener("submit", (e) => {
    if (!email.value) {
      emailError.classList.remove("hidden");
    }
    if (!checkbox.checked) {
      checkboxError.classList.remove("hidden");
    }
  });
}
